<template>
    <v-form @submit.prevent="submitLoginForm(loginForm)">
        <div class="space-y-default">
            <v-text class="font-bold">
                Already have an existing order?
            </v-text>

            <v-text>
                <!-- eslint-disable-next-line -->
                 Please click the <v-link :to="{ name: 'auth.forgot-password' }" class="inline">forgot password</v-link> link to reset your account's password and use the same email you entered when ordering.
            </v-text>

            <v-form-group>
                <v-form-label for="email">
                    Email
                </v-form-label>

                <v-form-input v-model="loginForm.username" type="email" name="username" required :disabled="isLoading" />
            </v-form-group>

            <v-form-group>
                <v-form-label for="email" class="text-white">
                    Password
                </v-form-label>

                <v-form-input v-model="loginForm.password" type="password" name="password" required :disabled="isLoading" />
            </v-form-group>

            <div class="grid grid-cols-1 sm:grid-cols-2 gap-8 place-items-center">
                <v-button type="submit" class="w-full justify-center" :disabled="isLoading">
                    Sign In
                </v-button>

                <v-button :to="{ name: 'auth.forgot-password' }" color="dark" class="w-full text-center">
                    Forgot Password
                </v-button>
            </div>
        </div>
    </v-form>
</template>

<script>
export default {
    data() {
        return {
            loginForm: {
                username: null,
                password: null,
            },
        };
    },
    methods: {
        submitLoginForm(payload) {
            this.toggleLoading();

            this.$store.dispatch("appModule/resetApplication")
                .finally(() => {
                    this.$oauth.login(payload)
                        .then(() => {
                            setTimeout(() => {
                                this.$router.push({ name: "panel.dashboard" });
                                this.toggleLoading();
                            }, 500);
                        })
                        .catch((error) => {
                            error.response.data.errors = {
                                username: [error.response.data.message],
                                password: [error.response.data.message],
                            };
                            this.$eventBus.$emit("error", error);
                            this.toggleLoading();
                        });
                });
        },
    },
};
</script>
